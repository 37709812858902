<script setup lang="ts">
import { KsIcon } from "@aschehoug/kloss";

const productCatalogue = useProductCatalogueStore();

// FIXME: Currently Digitalt supplement requires some special handling. We have to cleanup the entire packet structure before this can be solved in a smoother way.
const digitaltSupplementSlug = computed<string>(() => {
  const p = productCatalogue.products
    .filter(isPacket)
    .find((it) => it.name?.toLowerCase().includes("supplement"));

  // Sensible default
  return p?.slug.short ?? "digitalt-supplement-vgs";
});
</script>

<template>
  <section class="hero-banner">
    <div class="hero-banner__container">
      <h1 class="container__title heading1">
        Læremidler for grunnskolen og videregående skole
      </h1>
      <div class="container__cards">
        <CardsHomeHero
          accessibility-label="Første element av 3"
          title="Aunivers"
          subtitle="1–7 og 8–10"
          color="hero-green-yellow-light"
          slug="aschehoug-univers"
        />
        <CardsHomeHero
          accessibility-label="Andre element av 3"
          title="Aunivers"
          subtitle="vgs"
          color="hero-seagreen-light-blue"
          :slug="digitaltSupplementSlug"
        />
        <CardsHomeHero
          accessibility-label="Siste element av 3"
          title="modus"
          subtitle="vgs"
          color="hero-blue-pink"
          slug="modus"
        >
          <IconsLogosModus />
        </CardsHomeHero>
      </div>
      <NuxtLink to="/produktkatalog" class="container__show-all">
        <KsIcon id="arrow-long-right" />
        <span>Se hele produktkatalogen</span>
      </NuxtLink>
    </div>
  </section>
</template>

<style scoped lang="scss">
.hero-banner {
  background-color: $green-30;
  width: 100%;

  .hero-banner__container {
    display: flex;
    flex-direction: column;
    margin: auto auto;
    padding-top: 2rem;
    max-width: $max-width-mobile;

    @media screen and (min-width: $extra-small) {
      max-width: 55rem;
      margin: auto auto;
      padding-top: 4rem;
    }

    @media screen and (min-width: $small) {
      margin: auto auto;
    }

    .container__title {
      letter-spacing: 0.1rem;
      color: $seagreen-30;
      font-size: pixelsToRem(24);
      line-height: pixelsToRem(36);
      padding-bottom: 2rem;
      text-align: center;
      max-width: 90%;
      margin: 0 auto;

      @media screen and (min-width: $extra-small) {
        padding-bottom: 4rem;
        font-size: pixelsToRem(36);
        line-height: pixelsToRem(60);
      }

      @media screen and (min-width: $small) {
        padding-bottom: 4rem;
        font-size: pixelsToRem(40);
        line-height: pixelsToRem(54);
        max-width: 65%;
      }
    }

    .container__cards {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      gap: 2rem;
      flex-wrap: wrap;
      max-width: 21.5rem;
      width: 100%;

      @media screen and (min-width: $extra-small) {
        max-width: 55rem;
      }

      @media screen and (min-width: $small) {
        max-width: $max-width;
      }
    }

    .container__show-all {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $seagreen-30;
      text-decoration: none;
      margin: 1.5rem 0 3rem 0.4rem;
      font-size: pixelsToRem(16);
      transition: all 0.3s ease-in-out;

      @media screen and (min-width: $small) {
        justify-content: flex-start;
        font-size: pixelsToRem(18);
      }

      span {
        transition: all 0.3s ease-in-out;
        display: block;
        text-align: center;
        padding-left: 2rem;

        @media screen and (min-width: $extra-small) {
          text-align: left;
        }
      }

      svg {
        transform: scale(1.8);
        transition: all 0.3s ease-in-out;
      }
    }

    .container__show-all:hover {
      span {
        color: $yellow-20;
        transform: translateX(1.8rem);
      }
      svg {
        transform: translateX(1.8rem) scale(1.8);
        color: $yellow-20;
      }
    }
  }
}
</style>
