<script setup lang="ts">
const globalPending = usePendingStore().getPending;

await useArticles();

const { data: homeData, status, error } = await useHomepage();

useSeoMeta(useAddSeo());

useBodyBackground("green");
</script>

<template>
  <UiCommonsError v-if="error" :error="error" />
  <LayoutHeader />
  <UiCommonsLoader :loading="status === 'pending' || globalPending" />
  <main id="main-content" class="homepage-container" data-testid="homepage">
    <LayoutHeroBanner />
    <UiArticlesHighlighted
      v-if="homeData.highlightedArticles?.articles?.length"
      :data="homeData.highlightedArticles"
    />
    <UiStripesEvents />
    <UiStripesArticles
      background="yellow-light-green"
      title="Les siste nytt fra Aschehoug skole"
      :show-all="false"
      :no-top-margin="true"
      :range="[0, 3]"
      class="inspiration-cards"
    />
    <UiArticlesMostRead />
    <UiArticlesHomeMagazine />
    <UiStripesPodcasts
      v-if="homeData.podcastCards.length > 0"
      :podcasts="homeData.podcastCards"
    />
    <CardsAdvCard v-if="homeData.advCard" :adv="homeData.advCard" />
    <CtaNewsletter />
  </main>
  <LayoutFooter />
</template>

<style scoped lang="scss">
.homepage-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  margin: auto;
}

.inspiration-cards {
  padding-bottom: 4rem;
}
</style>
